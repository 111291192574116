
import Routers from './routes/routes';
function App() {
  return (
    <div className='w-auto m-auto'>

    <Routers/>  
    </div>
  );

}

export default App;
